import {createRouter, createWebHistory} from "vue-router";

const getPage = (page: string) => () => import(`@/pages/dashboard/${page}.vue`);

const routes = [
  {
    path: "/",
    component: getPage("Login")
  },
  {
    path: "/admin",
    component: getPage("Home"),
    children: [
      {
        path: "",
        redirect: "/admin/promotions"
      },
      {
        path: "reward-codes",
        component: getPage("RewardCode/Index"),
      },
      {
        path: "users",
        component: getPage("Users/Index"),
        children: [
          {
            path: "",
            component: getPage("Users/ListCustomers")
          },
          {
            path: "admins",
            component: getPage("Users/ListAdmins")
          },
          {
            path: "create-admin",
            component: getPage("Users/CreateAdmin")
          },
          {
            path: "verification-codes",
            component: getPage("Users/VerificationCodes")
          },
          {
            path: "deleted",
            component: getPage("Users/ListDeleted")
          },
          {
            path: ":userId",
            component: getPage("Users/User"),
            props: true,
          }
        ]
      },
      {
        path: "promotions",
        component: getPage("Promotion/Index"),
        children: [
          {
            path: "",
            component: getPage("Promotion/List")
          },
          {
            path: ":promotionId",
            component: getPage("Promotion/CreateOrUpdate"),
            props: true,
          },
          {
            path: "new",
            component: getPage("Promotion/CreateOrUpdate"),
            props: true,
          }
        ]
      },
      {
        path: "products",
        component: getPage("Product/Index"),
        children: [
          {
            path: "",
            component: getPage("Product/List")
          },
          {
            path: ":productId",
            component: getPage("Product/CreateOrUpdate"),
            props: true,
          },
          {
            path: "new",
            component: getPage("Product/CreateOrUpdate"),
            props: true,
          }
        ]
      },
    ]
  },
];

export const router = createRouter({
  routes,
  history: createWebHistory(),
})
